import * as React from 'react';
import styled from 'styled-components';
import { StaticImage } from 'gatsby-plugin-image';
import { navigate } from 'gatsby';

import { OrangeTryItButton } from 'components/Button';
import {
  Container,
  TextContainer,
  Blue,
  Grey,
  Beige,
} from 'components/Containers';
import { Details } from 'components/Details';
import { Seo } from 'components/Seo';
import { NavBar } from 'components/NavBar';
import { Footer } from 'components/Footer';
import { ContactSupport } from 'components/ContactSupport';
import {
  WhiteHeader,
  CenteredHeader,
} from 'components/Text';

import {
  largeMobileMQ,
  smallDesktopMQ,
  smallTabletMQ,
  extraLargeDesktopMQ,
  mediumDesktopMQ,
} from 'styles/breakpoints';

import Billing from 'assets/svgs/Billing.svg';
import Mobile from 'assets/svgs/Mobile.svg';
import Connected from 'assets/svgs/Connected.svg';
import Reminders from 'assets/svgs/Reminders.svg';
import Schedule from 'assets/svgs/Schedule.svg';
import Security from 'assets/svgs/Security.svg';
import Secure from 'assets/svgs/Secure.svg';
import Simple from 'assets/svgs/Simple.svg';
import Support from 'assets/svgs/Support.svg';

const CenteredButtonContainer = styled(Container)`
  text-align: center;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const PaddingLessTextContainer = styled(TextContainer)`
  padding: 0;
`;

const PriceValueText = styled.h2`
  font-style: normal;
  font-weight: bold;
  font-size: 80px;
  margin-top: 40px;
  line-height: 56px;
  /* identical to box height */

  text-align: center;

  color: ${({ theme }) => theme.colors.white};

  ${smallTabletMQ(`
    font-size: 60px;
    margin-top: 0;
    line-height: 50px;
  `)}
`;

const PerMonth = styled(PriceValueText)`
  font-size: 30px;
  line-height: 1.3;
`;

const AllDetailsContainer = styled.div`
  display: flex;
  padding-top: 60px;
  justify-content: space-between;
  text-align: center;
  p {
    max-width: 300px;
  }
  ${smallTabletMQ(`
    padding-top: 40px;
    flex-direction: column;
    align-items: center;
    p {
      max-width: unset;
    }
  `)}
  ${largeMobileMQ(`
    padding-top: 20px;
  `)}
`;

const SecondAllDetailsContainer = styled(AllDetailsContainer)`
  ${smallTabletMQ(`
    padding-top: 0;
    flex-direction: column;
    align-items: center;
    p {
      max-width: unset;
    }
  `)}
`;

const StyledDetails = styled(Details)`
  ${smallTabletMQ(`
    margin-bottom: 20px;
  `)}
`;

const StyledWhiteHeader = styled(WhiteHeader)`
  text-align: center;
`;

const PricingImageContainer = styled.div`
  display: flex;
  height: 500px;

  position: relative;
  justify-content: flex-end;
  img {
    border-radius: unset;
    position: absolute;
  }
  ${smallTabletMQ(`
    flex-direction: column;
    margin-top: -70px;
    height: 400px;
  `)}
  ${largeMobileMQ(`
    height: 350px;
    margin-top: -50px;
  `)};
  ${({ theme }) => smallTabletMQ(`background-color: ${theme.colors.blue[0]}`)}
  .backgroundImage {
    width: 100%;
    height: 100%;
    z-index: 0;
  }
`;

const RadialGradient = styled(Blue)`
  /* radial gradient light grey very low opacity */
  /* light grey at 10% opacity */
  background-image: radial-gradient(
    circle at 50% 50%,
    rgba(243, 244, 246, 0.08) 15%,
    transparent
  );
  ${smallTabletMQ(`
    background-image: radial-gradient(
      circle at 50% 100%,
      rgba(243, 244, 246, 0.12) 20%,
      transparent
    );
  `)}
`;

const PricingSide = styled.div`
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  padding-left: 15px;
  justify-content: space-evenly;
  z-index: 2;
  > * {
    z-index: 2;
    position: relative;
  }
  box-sizing: border-box;

  ${smallTabletMQ(`
    width: 100%;
    height: unset;
    margin: 0;
    padding: 0;
    align-items: center;
    justify-content: center;
  `)}
`;

const MobilePricingSide = styled(PricingSide)`
  ${smallTabletMQ(`display: none;`)}
`;

const Subtext = styled.p`
  color: ${({ theme }) => theme.colors.white};
  font-weight: 200;
  margin: 30px 5px 0 5px;
  ${smallTabletMQ(`margin-top: 15px;`)}
`;


const Row = styled.div`
  display: flex;
`;

const FlexEndRow = styled.div`
  display: flex;
  align-items: flex-end;
`;

const PricingContainer = styled(Container)`
  width: 100%;
  justify-content: flex-end;
  ${smallTabletMQ(`padding: 0`)}
`;

const PricingHeader = styled(StyledWhiteHeader)`
  align-items: flex-end;
  display: flex;
  line-height: 1.5;
  justify-content: flex-end;
  ${extraLargeDesktopMQ(`
    font-size: 35px;
  `)}
  ${mediumDesktopMQ(`
    font-size: 28px;
  `)}
  ${smallTabletMQ(`
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 15px 0;
    font-size: 25px;
  `)}
  ${largeMobileMQ(`
    font-size: 20px;
  `)}

  &::after {
    ${({ theme }) => smallTabletMQ(`
      background-color: ${theme.colors.blue[0]};
      opacity: 0.8;
      content: "";
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      position: absolute;
      z-index: -1;
    `)}
  }
`;

const PricingDetails = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  ${smallTabletMQ(`
    display: none;
  `)}
`;

const MobilePricingDetails = styled(PricingDetails)`
  background-color: ${({ theme }) => theme.colors.blue[0]};
  display: none;
  ${smallTabletMQ(`
    background-image: radial-gradient(
      circle at 50% 50%,
      rgba(243, 244, 246, 0.06) 15%,
      transparent
    );
    margin: 0;
    justify-content: flex-start;
    align-items: center;
    display: flex;
    padding:  30px 0;
  `)}
`;

const TranslucentBlue = styled(Blue)`
  opacity: 0.8;
  position: absolute;
  height: 100%;
  width: 50%;
  z-index: 1;
  top: 0;
  bottom: 0;
  right: 0;
  ${smallTabletMQ(`
    display: none;
  `)}
`;

const BigOrangeTryItButton = styled(OrangeTryItButton)`
  font-size: 20px;
  font-weight: bold;
  width: 288px;
  height: 48px;
  margin-top: 30px;
  ${smallDesktopMQ(`
    font-size: 18px;
  `)}
  ${smallTabletMQ(`
    width: 300px;
  `)}
  ${largeMobileMQ(`
    width: 250px;
    height: 44px;
    margin-top: 15px;
  `)};
  ${({ theme }) => `
    border: 1px solid ${theme.colors.yellow[0]} !important;
    background-color: ${theme.colors.yellow[0]} !important;
    color: ${theme.colors.white}  !important;
  `}
`;

const MarginBigOrangeTryItButton = styled(BigOrangeTryItButton)`
  margin-top: 60px;
  ${smallTabletMQ(`
    margin-top: 30px;
  `)}
  ${largeMobileMQ(`
    width: 300px;
  `)};
`;

const Pricing = () => (
  <>
    <NavBar />
    <Seo
      title="WireBuddy - Pricing"
      description="All your jobs, simply in one place"
      lang="en"
      image="src/assets/svgs/WireBuddyLogoPartial.svg"
    />
    <PricingImageContainer>
      <StaticImage
        alt="pricing man drilling"
        src="../images/pricing-man.jpeg"
        style={{
          position: 'absolute',
          left: 0,
          top: 0,
          bottom: 0,
          right: 0,
        }}
        className="backgroundImage"
        loading="eager"
        placeholder="blurred"
        quality={100}
      />
      <PricingContainer>
        <MobilePricingSide />
        <PricingSide>
          <PricingHeader>
            Save time, get organized, <br/> get paid faster.
          </PricingHeader>
          <PricingDetails>
            <FlexEndRow>
              <PriceValueText>$29</PriceValueText>
              <PerMonth>/mo</PerMonth>
            </FlexEndRow>
            <Row>
              <Subtext>Recurring Billing</Subtext>
              <Subtext>•</Subtext>
              <Subtext>Cancel Anytime</Subtext>
            </Row>
            <BigOrangeTryItButton
              onClick={() => {
                navigate('/signup')?.catch(() => undefined);
              }}
            >
              Start your free Trial
            </BigOrangeTryItButton>
          </PricingDetails>
        </PricingSide>
      </PricingContainer>
      <TranslucentBlue/>
    </PricingImageContainer>
    <MobilePricingDetails>
      <FlexEndRow>
        <PriceValueText>$29</PriceValueText>
        <PerMonth>/mo</PerMonth>
      </FlexEndRow>
      <Row>
        <Subtext>Recurring Billing</Subtext>
        <Subtext>•</Subtext>
        <Subtext>Cancel Anytime</Subtext>
      </Row>
      <BigOrangeTryItButton
        onClick={() => {
          navigate('/signup')?.catch(() => undefined);
        }}
      >
        Start your free Trial
      </BigOrangeTryItButton>
    </MobilePricingDetails>
    <Beige>
      <Container>
        <PaddingLessTextContainer>
          <CenteredHeader>
            Less time tracking, more time doing
          </CenteredHeader>
          <AllDetailsContainer>
            <StyledDetails
              name="Simple"
              Svg={Simple}
              details={
                'Easy to use and fast! ' +
                'Reclaim hours of paperwork with automated solutions.'
              }
            />

            <StyledDetails
              name="Mobile"
              Svg={Mobile}
              details={
                'Send quotes on the job site or from the road. ' +
                'WireBuddy goes where you go!'
              }
            />

            <StyledDetails
              name="Secure"
              Svg={Secure}
              details={
                'The first encrypted contractor tool to keep ' +
                'your data safe from prying eyes.'
              }
            />
          </AllDetailsContainer>
        </PaddingLessTextContainer>
      </Container>
    </Beige>
    <RadialGradient>
      <CenteredButtonContainer>
        <PaddingLessTextContainer>
          <StyledWhiteHeader>
            Bringing your contract business online
            <br/> has never been easier
          </StyledWhiteHeader>
        </PaddingLessTextContainer>
        <MarginBigOrangeTryItButton
          onClick={() => {
            navigate('/signup')?.catch(() => undefined);
          }}
        >
          Start your free Trial
        </MarginBigOrangeTryItButton>
      </CenteredButtonContainer>
    </RadialGradient>
    <Grey>
      <Container>
        <PaddingLessTextContainer>
          <CenteredHeader>
            All WireBuddy users benefit from
          </CenteredHeader>
          <AllDetailsContainer>
            <StyledDetails
              name="Industry-leading Security"
              details={
                'Your information is safe with us! ' +
                'Sleep easy knowing that your quotes, clients, and ' +
                'personal information is locked away with bank-level security measures.'
              }
              Svg={Security}
            />

            <StyledDetails
              name="Seamless Scheduling"
              details={
                'Track and schedule your current and upcoming ' +
                'jobs so you never miss out on a new opportunity. '
              }
              Svg={Schedule}
            />

            <StyledDetails
              name="Billing & Payments"
              details={
                'Send invoices and track payments all in one place. '
              }
              Svg={Billing}
            />
          </AllDetailsContainer>
          <SecondAllDetailsContainer>
            <StyledDetails
              name="Reminders"
              details={
                'Spend less time chasing clients and more time cashing cheques.'
              }
              Svg={Reminders}
            />

            <StyledDetails
              name="Connected"
              details={
                'Everything in one tool ' +
                'from communicating with your clients to getting your next job.'
              }
              Svg={Connected}
            />

            <StyledDetails
              name="Support 7 days a week"
              details={
                'No days off! We know that a lot of work happens on the weekend ' +
                'and we’re here to help you 7-days a week.'
              }
              Svg={Support}
            />
          </SecondAllDetailsContainer>
        </PaddingLessTextContainer>
      </Container>
    </Grey>
    <ContactSupport />
    <Footer isBordered/>
  </>
);

export default Pricing;
