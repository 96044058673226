import React from 'react';
import styled from 'styled-components';
import { StaticImage } from 'gatsby-plugin-image';
import { navigate } from 'gatsby';

import { TryItButton } from 'components/Button';
import {
  Beige,
  Container,
  TextContainer,
} from 'components/Containers';

import { smallTabletMQ } from 'styles/breakpoints';


const SubHeader = styled.h2`
  padding-bottom: 20px;
  font-style: normal;
  font-weight: bold;
  color: ${({ theme }) => theme.colors.blue[0]};
`;

const TopImage = styled.div`
  ${smallTabletMQ('margin-top: 30px;')}
  flex: 1;
`;

const FooterDetails = styled.h3`
  margin: 15px 0;
`;
export const ContactSupport = () => (
  <Beige>
    <Container>
      <TextContainer>
        <h1>
          Get the help you need, <br/>
          every step of the way.
        </h1>

        <FooterDetails>
          Contact our support 24/7, whenever you’re troubleshooting issues - big or small.
        </FooterDetails>

        <>
          <SubHeader>
              Ready to get started?
          </SubHeader>

          <TryItButton onClick={async() => navigate('/signup')}>
            Try it for free
          </TryItButton>
        </>

      </TextContainer>
      <TopImage>
        <StaticImage
          alt="Man using laptop"
          src="../../images/contactsupport.jpeg"
          placeholder='blurred'
        />
      </TopImage>
    </Container>
  </Beige>
);
